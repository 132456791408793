import Vue from "vue";
import VueRouter from "vue-router";
import { homeRoutes } from "@/modules/home/router/";
import { loginRoutes } from "@/modules/login/router/";
import { inventoryRoutes } from "@/modules/inventory/router/";
import { settingsRoutes } from "@/modules/settings/router";
import { errorRoutes } from "@/modules/errors/router/";

Vue.use(VueRouter);

const routes = [
  ...loginRoutes,
  ...homeRoutes,
  ...inventoryRoutes,
  ...settingsRoutes,
  ...errorRoutes,
];

const router = new VueRouter({
  mode: "history",
  linkActiveClass: "Active",
  base: process.env.BASE_URL,
  routes,
});

export default router;
