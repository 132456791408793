<template>
  <div id="app">
    <div v-if="local !== undefined && this.$route.path !== '/login'">
      <MenuComponent />
    </div>
    <transition name="slide" mode="out-in">
      <router-view class="mt-0" />
    </transition>
  </div>
</template>

<script>
import MenuComponent from "@/components/BaseNav/NavMenuDesktop.vue";

export default {
  components: {
    MenuComponent,
  },
  data() {
    return {
      local: localStorage.__access,
    };
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  height: 100vh;
  color: #2c3e50;
}

.slide-enter,
.slide-leave-to {
  transform: translate(-50px);
  opacity: 0;
}

.slide-enter-active,
.slide-leave-active {
  transition: all 0.3s;
}
</style>
