export const homeRoutes = [
  {
    path: "/",
    name: "home",
    beforeEnter: (to, from, next) => {
      if (!localStorage.__access) next({ name: "login" });
      next();
    },
    component: () => import("@/modules/home/HomeView.vue"),
    children: [
      {
        path: "/action/:id",
        name: "action_home",
        component: () => import("@/modules/home/layout/ActionHomeView.vue"),
      },
      {
        path: "/:id/data",
        name: "data_home",
        component: () => import("@/modules/home/layout/DataHomeView.vue"),
      },
      {
        path: "/historic/:code",
        name: "historic_home",
        component: () => import("@/modules/home/layout/HistoricHomeView.vue"),
      },
      {
        path: "/identifier_code/:identifier_code",
        name: "item_filter",
        component: () => import("@/modules/home/layout/ActionHomeView.vue"),
      },
    ],
  },
];
