export const inventoryRoutes = [
  {
    path: "/inventory",
    name: "inventory",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "@/modules/inventory/InventoryView.vue"
      ),
  },
  {
    path: "/create_new",
    name: "create_iten",
    component: () =>
      import("@/modules/inventory/layouts/actions/CreateItenInventory.vue"),
  },
  {
    path: "/edit/:code",
    name: "edit-inventory",
    component: () =>
      import("@/modules/inventory/layouts/actions/EditInventory.vue"),
  },
  {
    path: "/maintenance/:id",
    name: "maintenance-inventory",
    component: () =>
      import("@/modules/inventory/layouts/actions/MaintenanceInventory.vue"),
  },
];
