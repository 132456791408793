<template>
  <nav class="navbar p-3 navbar-expand-lg bg-light shadow-sm">
    <div class="container-fluid">
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarTogglerDemo03"
        aria-controls="navbarTogglerDemo03"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span>
          <i class="fa-solid fa-bars"></i>
        </span>
      </button>
      <div class="collapse navbar-collapse" id="navbarTogglerDemo03">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <router-link class="nav-link" :to="{ name: 'home' }"
              ><i class="fa-solid fa-chart-pie"></i> Dashboard</router-link
            >
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :to="{ name: 'inventory' }">
              <i class="fa-solid fa-dolly"></i>
              Estoque
            </router-link>
          </li>

          <li v-show="false" class="nav-item">
            <router-link class="nav-link" to="#">
              <i class="fa-solid fa-chart-simple"></i>
              Estatisticas
            </router-link>
          </li>

          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i class="fa-solid fa-gear"></i>
              Configurações
            </a>
            <ul class="dropdown-menu p-1">
              <li class="mb-3">
                <router-link
                  :to="{ name: 'my-profile' }"
                  class="dropdown-item p-3 shadow radius"
                >
                  <span class="iten-detail p-2">
                    <i class="fa-solid fa-user-tie" style="padding: 2px"></i>
                  </span>
                  {{ user_name }}
                </router-link>
              </li>

              <li>
                <router-link
                  :to="{ name: 'list-companies' }"
                  class="dropdown-item mb-2"
                >
                  <span class="iten-detail">
                    <i class="fa-solid fa-people-group"></i>
                  </span>
                  Gerenciar Clientes
                </router-link>
              </li>

              <li>
                <router-link
                  :to="{ name: 'add-new-company' }"
                  class="dropdown-item mb-2"
                >
                  <span class="iten-detail">
                    <i class="fa-regular fa-handshake"></i>
                  </span>
                  Adicionar Clientes
                </router-link>
              </li>

              <li>
                <router-link
                  :to="{ name: 'add-user' }"
                  class="dropdown-item mb-2"
                >
                  <span class="iten-detail">
                    <i class="fa-solid fa-user-plus"></i>
                  </span>
                  Adicionar usuario
                </router-link>
              </li>
              <li>
                <router-link
                  :to="{ name: 'manage-users' }"
                  class="dropdown-item mb-2"
                >
                  <span class="iten-detail">
                    <i class="fa-solid fa-users-gear"></i>
                  </span>
                  Gerenciar usuarios
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'logout' }" class="dropdown-item">
                  <span class="iten-detail">
                    <i class="fa-solid fa-door-open"></i>
                  </span>
                  Sair
                </router-link>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "MenuComponent",
  data() {
    return {
      search: "",
      user_name:
        localStorage.getItem("user") == ""
          ? "undefined"
          : localStorage.getItem("user"),
    };
  },
  methods: {
    filter() {
      this.$router
        .push({ path: `/identifier_code/${this.search}` })
        .catch((err) => {
          console.log(err);
        });
      return;
    },
  },
};
</script>

<style scoped lang="scss">
nav {
  padding: 20px;
  z-index: 2;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #56baed;
      font-size: 19px;
    }
    &:focus {
      border: none;
    }
  }
  .radius {
    border-radius: 5px;
  }

  .iten-detail {
    background-color: rgba(204, 204, 204, 0.661);
    padding: 8px;
    border-radius: 50%;
    margin-right: 5px;

    i {
      // padding: 1px;
    }
  }
}
</style>
