export const settingsRoutes = [
  {
    path: "/settings",
    name: "settings",
    component: () => import("@/modules/settings/SettingsView.vue"),
    beforeEnter: (to, from, next) => {
      if (!localStorage.__access) next({ name: "login" });
      next();
    },
    children: [
      {
        path: "/settings/my-profile",
        name: "my-profile",
        component: () => import("@/modules/settings/layout/ProfileView.vue"),
      },
      {
        path: "/settings/users",
        name: "manage-users",
        component: () =>
          import("@/modules/settings/layout/ManageUsersView.vue"),
      },
      {
        path: "/settings/add-user",
        name: "add-user",
        component: () => import("@/modules/settings/layout/AddUsersView.vue"),
      },
      {
        path: "/settings/collaborators",
        name: "list-companies",
        component: () => import("@/modules/settings/layout/CompaniesList.vue"),
      },
      {
        path: "/settings/add-company",
        name: "add-new-company",
        component: () =>
          import("@/modules/settings/layout/companies/AddCompany.vue"),
      },
      {
        path: "settings/edit/:id",
        name: "edit-company",
        component: () =>
          import("@/modules/settings/layout/companies/EditCompany.vue"),
      },
    ],
  },
];
