export const loginRoutes = [
  {
    path: "/login",
    alias: "/login",
    name: "login",
    component: () => import("@/modules/login/LoginView.vue"),
    beforeRouteEnter(to, from, next) {
      if (!localStorage.__access) next({ name: "login" });
      next();
    },
    children: [
      {
        path: "/sign-in",
        name: "login-sign-in",
        component: () => import("@/modules/login/layout/SignIn.vue"),
      },
      {
        path: "/sign-up",
        name: "login-sign-up",
        component: () => import("@/modules/login/layout/SignUp.vue"),
      },
      {
        path: "/forgot",
        name: "login-forgot",
        component: () => import("@/modules/login/layout/ForgotPassword.vue"),
      },
      {
        path: "/confirm-code",
        name: "confirm-code",
        component: () => import("@/modules/login/layout/SetCode.vue"),
      },
    ],
  },
  {
    path: "/logout",
    name: "logout",
    beforeEnter: (to, from, next) => {
      localStorage.clear();
      next({ name: "login" });
    },
  },
];
